import { firebaseApp, providers } from "../firebase/firebase.config";

const signIn = async () => {
  // should work?
  // const userToken = await firebaseApp.auth().currentUser.getIdToken()
  // const cred = firebase.auth.GoogleAuthProvider.credential(userToken)
  // const userCred = await firebaseApp.auth().currentUser.linkWithCredential(cred)
  // console.log('converted anon account', userCred)

  firebaseApp.auth().signInWithPopup(providers.googleProvider);
};

const signOut = () => {
  firebaseApp.auth().signOut();
};

const signInAnonymously = () => {
  firebaseApp
    .auth()
    .signInAnonymously()
    .catch((error) => console.log(error));
};

// https://johnwcassidy.medium.com/firebase-authentication-hooks-and-context-d0e47395f402
function onAuthStateChange(callback) {
  return firebaseApp.auth().onAuthStateChanged((user) => {
    if (user) {
      callback({
        ...user,
        loggedIn: true,
      });
    } else {
      signInAnonymously();
      callback({ loggedIn: false });
    }
  });
}

export { signIn, signOut, onAuthStateChange, signInAnonymously };
