const Modal = (props) => {
  return (
    props.show && (
      <div className="modal fixed left-0 top-0 right-0 bottom-0 flex justify-center items-center modal-bg">
        <div className="modal-content w-96 bg-white p-4 rounded-lg">
          <div className="modal-header">
            <p className="inline-block text-3xl font-extrabold text-gray-900 tracking-tight">
              New datasource
            </p>
            <hr></hr>
          </div>
          <div className="modal-body">
            <label className="w-full">Type</label>
            <select className="w-full">
              <option>Postgres</option>
              <option>Firebase</option>
            </select>
          </div>
          <div className="modal-footer">
            <button
              onClick={props.close}
              className="flex-none bg-pink-400 hover:bg-pink-500 transition-colors duration-200 text-gray-900 font-semibold rounded-lg py-3 px-4 float-right"
            >
              close
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
