import { useContext, useEffect, useState } from "react";
import { ProtoAppType } from "../types/types";
import { v4 as uuidv4 } from "uuid";
import { db } from "../firebase/firebase.config";
import { Redirect } from "react-router-dom";
import { UserContext } from "../App";

// Generates a very basic app right now.
// In the future, we could imagine this function generating different types of apps,
// or apps set up with particular components.
function appGenerator(user): ProtoAppType {
  const newApp: ProtoAppType = {
    name: "",
    owner: user.uid,
    created: Date.now(),
    modified: Date.now(),
    components: [],
    uuid: uuidv4(),
  };
  return newApp;
}

function NewProtoApp() {
  const user = useContext(UserContext);
  const [app, setApp] = useState<ProtoAppType>(null);

  useEffect(() => {
    if (!user || !user.loggedIn) return;
    const newApp = appGenerator(user);
    db.collection("apps")
      .doc(newApp.uuid)
      .set(newApp)
      .then(() => setApp(newApp))
      .catch((error) => console.error("Error saving app: ", error));
  }, [user]);

  return app ? (
    <Redirect to={`/app/${app.uuid}`}></Redirect>
  ) : (
    <CreatingNewAppSpinner />
  );
}

function CreatingNewAppSpinner() {
  return (
    <div className="animate-pulse flex items-center justify-center h-screen">
      <h1>Creating new app...</h1>
    </div>
  );
}

export default NewProtoApp;
