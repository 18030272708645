import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDArG3llsaVAIwvzdgsiXh96MIZJQvL_rY",
  authDomain: "good-pane.firebaseapp.com",
  projectId: "good-pane",
  storageBucket: "good-pane.appspot.com",
  messagingSenderId: "728555236703",
  appId: "1:728555236703:web:30e3b85eb31540b5549aea",
  measurementId: "G-XBNB5V0DZ1",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export { db, firebaseApp, providers, firebase };
