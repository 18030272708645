import { ControlPosition } from "react-draggable";
import { RouteComponentProps } from "react-router";
import firebase from "firebase/app";

enum ProtoComponentType {
  Text,
  Input,
  Container,
  Table,
  Button,
}
export { ProtoComponentType };

type ProtoComponent = {
  name: string;
  type: ProtoComponentType;
  position: ControlPosition;
};
export type { ProtoComponent };

type ProtoAppType = {
  name: string;
  owner: string;
  created: number;
  modified: number;
  uuid: string;
  components: ProtoComponent[];
};
export type { ProtoAppType };

type PathParamsType = {
  uuid: string;
};

export type EditorProps = RouteComponentProps<PathParamsType> & {};

export type ProtoAppProps = {
  app: ProtoAppType;
};

export type ProtoAppState = ProtoAppType;

export type EditorState = {
  app: ProtoAppType;
  users: [];
  datasources: [];
};

type User = firebase.User;
export type GoodPaneUser = User & {
  loggedIn: boolean;
  notLoaded: boolean;
};
