import ProtoComponentTagBox from "../components/ProtoComponentTagBox";

function Text(props) {
  const component = (
    <div className="max-h-full h-full w-full">
      <h1 className="">Text</h1>
    </div>
  );

  return (
    <ProtoComponentTagBox
      name={props.component.name}
      component={component}
    ></ProtoComponentTagBox>
  );
}

export default Text;
