import { useEffect, useRef, useState } from "react";
import NewMenu from "./NewMenu";

const NewButton = (props) => {
  const [showMenu, setShowMenu] = useState(true);
  const ref = useRef(null);
  function handleButtonClick() {
    setShowMenu(!showMenu);
  }

  const handleMenuButtonClick = () => {
    console.log("handling click from newbutton");
    setShowMenu(false);
    props.clickHandler();
  };

  useEffect(() => {
    const didClickOutside = (e) => {
      const refContainsSelf = !ref.current?.contains(e.target);
      if (showMenu && ref.current && refContainsSelf) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", didClickOutside);

    return () => {
      document.removeEventListener("mousedown", didClickOutside);
    };
  });

  return (
    <div className="absolute bottom-2 right-2" ref={ref}>
      <button
        onClick={handleButtonClick}
        className="rotate-180 rounded-full bg-pink-500 w-12 h-12 "
      >
        <svg
          className="m-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path fill="white" d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" />
        </svg>
      </button>
      {showMenu && <NewMenu clickHandler={handleMenuButtonClick}></NewMenu>}
    </div>
  );
};

export default NewButton;
