import { useCallback, useContext } from "react";
import { signIn, signOut } from "../auth/auth";
import { UserContext } from "../App";
import { GoodPaneUser } from "../types/types";

function UserBubble() {
  const user = useContext(UserContext);

  const requestLogin = useCallback(() => {
    signIn();
  }, []);

  const requestLogout = useCallback(() => {
    signOut();
  }, []);

  const loggedIn = user && user.loggedIn && !user.isAnonymous;
  return (
    <div className="absolute bottom-0 mb-2 rounded-r-lg text-white font-mono inline-flex gp-bg ease-linear transition-all duration-300">
      {loggedIn && <UserInfo {...user} />}

      {loggedIn ? (
        <LogoutButton fn={requestLogout} />
      ) : (
        <button
          className="bg-pink-500 text-white  font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none focus:outline-none m-2 ease-linear transition-all duration-150"
          onClick={requestLogin}
        >
          login
        </button>
      )}
    </div>
  );
}

function LogoutButton(props) {
  return (
    <button
      className="bg-pink-500 text-white  font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none focus:outline-none m-2 ease-linear transition-all duration-150"
      onClick={props.fn}
    >
      logout
    </button>
  );
}

function UserInfo(user: GoodPaneUser) {
  return (
    <div className="ml-2 p-2">
      <div className="text-s">{user.displayName}</div>
      <div className="text-xs">{user.email}</div>
    </div>
  );
}

export default UserBubble;
