import { Component } from "react";

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props };
    }

    render() {
        return (
            <div className="p-4 md:w-1/3 mw-200">
                <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                    <div className="p-6">
                        <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                            I am a container
                        </h2>
                    </div>
                </div>
            </div>
        );
    }
}

export default Container;
