import AppState from "../components/AppState";
import ComponentBox from "../components/ComponentsBox";
import ProtoApp from "../components/ProtoApp";
import { v4 as uuidv4 } from "uuid";
import { withRouter } from "react-router-dom";
import { ProtoComponent } from "../types/types";
import { db } from "../firebase/firebase.config";
import { ProtoAppType } from "../types/types";
import UserBubble from "../components/UserBubble";
import { GRID_SIZE_IN_PIXELS } from "../utils/contants";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import YourApps from "../components/YourApps";
import YourResources from "../components/YourResources";
import NewButton from "../components/NewButton";
import Modal from "../components/modals/BaseModal";
// import useKeyPress from "../hooks/useKeyPress";
// import ShortcutHandler from "../components/ShortcutHandler";
// import { useScrollLock } from "@mantine/hooks";

function Editor() {
  const [app, setApp] = useState<ProtoAppType>({
    name: "",
    created: 0,
    modified: 0,
    owner: "",
    uuid: "",
    components: [],
  });

  //   useScrollLock(true);

  const { uuid } = useParams();

  const [showModal, setShowModal] = useState(false);

  // Listen for changes on the ProtoApp
  useEffect(() => {
    const onAppChange = (appSnapshot) => {
      setApp((previousState) => {
        return {
          ...(appSnapshot.data() as ProtoAppType),
          components: previousState.components,
        };
      });
    };
    const unsubscribe = db.collection("apps").doc(uuid).onSnapshot(onAppChange);
    return () => unsubscribe();
  }, [uuid]);

  // Listen for changes on the ProtoComponents
  useEffect(() => {
    const onComponentsChange = (querySnapshot) => {
      const components = querySnapshot.docs.map((component) => {
        return {
          ...(component.data() as ProtoComponent),
        };
      });
      setApp((preivousState) => {
        return { ...preivousState, components };
      });
    };
    const unsubscribe = db
      .collection("apps")
      .doc(uuid)
      .collection("components")
      .onSnapshot(onComponentsChange);
    return () => unsubscribe();
  }, [uuid]);

  const createNewProtoComponent = (type): ProtoComponent => {
    return {
      name: `${type.toLowerCase() + "_component_" + uuidv4()}`,
      type: type,
      position: {
        x:
          Math.ceil((Math.random() * window.innerWidth) / GRID_SIZE_IN_PIXELS) *
          GRID_SIZE_IN_PIXELS,
        y:
          Math.ceil(
            (Math.random() * window.innerHeight) / GRID_SIZE_IN_PIXELS
          ) * GRID_SIZE_IN_PIXELS,
      },
    };
  };

  const saveComponent = (component: ProtoComponent) => {
    db.collection("apps")
      .doc(uuid)
      .collection("components")
      .doc(component.name)
      .set(component);
  };

  const addProtoComponent = (e) => {
    const componentType = e.target.id;
    const appModelComponents: ProtoComponent[] = app.components || [];
    const newComponent = createNewProtoComponent(componentType);

    appModelComponents.push(newComponent);

    setApp({
      ...app,
      components: appModelComponents,
      modified: Date.now(),
    });

    saveComponent(newComponent);
  };

  const handleNewButtonClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="App grid-bg overflow-hidden overflow-y-auto">
      <ProtoApp app={app}></ProtoApp>
      <ComponentBox data={app} handler={addProtoComponent}></ComponentBox>
      <YourResources></YourResources>
      <YourApps></YourApps>
      <AppState data={app}></AppState>
      <UserBubble></UserBubble>
      <NewButton clickHandler={handleNewButtonClick}></NewButton>
      <Modal close={closeModal} show={showModal}></Modal>
    </div>
  );
}

export default withRouter(Editor);
