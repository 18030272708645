import { Button } from "@mantine/core";
import { ProtoComponentType } from "../types/types";
import Draggable from "react-draggable";
import { GRID_SIZE_IN_PIXELS } from "../utils/contants";

type ComponentBoxProps = any;

function ComponentBox(props: ComponentBoxProps) {
  const createComponentList = () => {
    const components = [];
    for (const value in ProtoComponentType) {
      if (isNaN(Number(value))) components.push(value);
    }
    return components;
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    props.handler(e);
  };

  const buildCompontTags = (componentList) => {
    return componentList.map((c) => {
      return (
        <div key={c}>
          <h3>{c}</h3>
          <Button id={c} onClick={handleButtonClick}>
            Add
          </Button>
        </div>
      );
    });
  };

  return (
    <Draggable
      axis="both"
      position={null}
      grid={[GRID_SIZE_IN_PIXELS, GRID_SIZE_IN_PIXELS]}
      scale={1}
      bounds={"parent"}
    >
      <div className="m-2 trform gp-bg shadow-2xl rounded-lg overflow-hidden w-32">
        <div className="py-4 px-4 mt-1 text-white font-mono">
          <h1>Components</h1>
          <hr></hr>
          {buildCompontTags(createComponentList())}
        </div>
      </div>
    </Draggable>
  );
}

export default ComponentBox;
