function ProtoComponentTagBox(props) {
  return (
    <div className="h-full proto-tag-container">
      <p className="text-white whitespace-nowrap px-1 pt-0.5 rounded-t-sm rounded-r-sm font-mono bg-pink text-tiny absolute -left-0.5 -top-3.5">
        {props.name}
      </p>
      {props.component}
    </div>
  );
}

export default ProtoComponentTagBox;
