import { Component } from "react";
import Draggable from "react-draggable";
import Text from "../proto_components/Text";
import Input from "../proto_components/Input";
import Table from "../proto_components/Table";
import Button from "../proto_components/Button";
import Map from "../proto_components/Map";
import Container from "../proto_components/Container";
import { ProtoComponent, ProtoAppState, ProtoAppProps } from "../types/types";
import { db } from "../firebase/firebase.config";
import { GRID_SIZE_IN_PIXELS } from "../utils/contants";
import _ from "lodash";
class ProtoApp extends Component<ProtoAppProps, ProtoAppState> {
  getProtoComponentFromType = (component: ProtoComponent) => {
    const componentType = String(component.type);
    console.log(component);

    switch (componentType) {
      case "Text":
        return <Text component={component}></Text>;
      case "Input":
        return <Input component={component}></Input>;
      case "Button":
        return <Button component={component}></Button>;
      case "Container":
        return <Container></Container>;
      case "Map":
        return <Map></Map>;
      case "Table":
        return <Table></Table>;
    }
  };

  renderAppFromListOfComponents = (components: ProtoComponent[]) => {
    const protoComponents: ProtoComponent[] = this.props.app.components;

    const testJsx = protoComponents.map((pc) => {
      return (
        <Draggable
          axis="both"
          position={pc.position}
          grid={[GRID_SIZE_IN_PIXELS, GRID_SIZE_IN_PIXELS]}
          onDrag={this.handleDrag}
          onStop={this.handleDragEnd}
          bounds={"parent"}
        >
          <div
            className="m-2 h-12 w-64 proto-component absolute trform"
            id={pc.name}
          >
            {this.getProtoComponentFromType(pc)}
          </div>
        </Draggable>
      );
    });

    return testJsx;
  };

  handleDrag = (event, data) => {
    this.saveNewComponentPositionToDb(data);
  };

  saveNewComponentPositionToDb = _.debounce((data) => {
    console.log(this.props);
    const target = data.node.id;

    db.collection("apps")
      .doc(this.props.app.uuid)
      .set(
        {
          modified: Date.now(),
        },
        { merge: true }
      )
      .then(() => {
        console.log("Updated app modification time");
      })
      .catch((e) => {
        console.log("Failed to updat app modification time", e);
      });

    db.collection("apps")
      .doc(this.props.app.uuid)
      .collection("components")
      .doc(target)
      .set(
        {
          position: this.snapCoordtoGrid(data),
        },
        { merge: true }
      )
      .then(() => {
        console.log("Saved component mid-drags");
      })
      .catch((e) => {
        console.log(e);
      });
  }, 100);

  snapCoordtoGrid = (coord) => {
    console.log("got coord: ", coord);

    const snappedCord = {
      x: Math.round(coord.x / GRID_SIZE_IN_PIXELS) * GRID_SIZE_IN_PIXELS,
      y: Math.round(coord.y / GRID_SIZE_IN_PIXELS) * GRID_SIZE_IN_PIXELS,
    };
    console.log("snapped coord: ", snappedCord);
    return snappedCord;
  };

  handleDragEnd = (event, data) => {
    const shouldSaveNewPostition = data.deltaX !== 0 && data.deltaX !== 0;
    if (!shouldSaveNewPostition) return;

    const target = data.node.id;

    db.collection("apps")
      .doc(this.props.app.uuid)
      .collection("components")
      .doc(target)
      .set(
        {
          position: this.snapCoordtoGrid(data),
        },
        { merge: true }
      )
      .then(() => {
        console.log("Saved component on drag end");
      })
      .catch((e) => {
        console.log(e);
      });

    // also set local state
    const updated = this.props.app.components.map((component) => {
      if (component.name === target) {
        component.position = this.snapCoordtoGrid(data);
      }
      return component;
    });
    this.setState({
      components: updated,
    });
  };

  render() {
    const protoComponents: ProtoComponent[] = this.props.app.components;
    return (
      <div className="absolute h-screen w-screen top-0 left-0">
        {this.renderAppFromListOfComponents(protoComponents)}
      </div>
    );
  }
}

export default ProtoApp;
