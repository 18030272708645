import ProtoComponentTagBox from "../components/ProtoComponentTagBox";

function Input(props) {
  const component = (
    <input className="max-h-full px-2 h-full w-full shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></input>
  );
  return (
    <ProtoComponentTagBox
      name={props.component.name}
      component={component}
    ></ProtoComponentTagBox>
  );
}

export default Input;
