import { useState, useContext } from "react";
import { db } from "../firebase/firebase.config";
import { UserContext } from "../App";
import { ProtoAppType } from "../types/types";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function YourApps() {
  const user = useContext(UserContext);
  const [apps, setApps] = useState<ProtoAppType[]>([]);

  // Listen for changes to my apps
  useEffect(() => {
    if (!user) return null;
    const onAppsChange = (data) => {
      const newApps = [];
      data.forEach((d) => {
        console.log(d.data());
        newApps.push(d.data());
      });
      setApps(newApps);
    };

    const unsubscribe = db
      .collection("apps")
      .where("owner", "==", user.uid)
      .orderBy("modified", "desc")
      .onSnapshot(onAppsChange);
    return () => unsubscribe();
  }, [user]);

  return (
    <div className="gp-bg p-4 bottom-20 absolute w-128 rounded-r-lg text-white font-mono">
      <h4>Your apps ({apps.length})</h4>
      <ul className="max-h-20 text-xs overflow-y-scroll">
        {apps.map((a) => (
          <li key={a.uuid}>
            {" "}
            <Link key={a.uuid} to={a.uuid}>
              {a.uuid}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default YourApps;
