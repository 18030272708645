import './App.css';
import {
  Switch,
  Route
} from "react-router-dom";
import Editor from './pages/Editor';
import NewProtoApp from './pages/NewProtoApp';
import { useState } from 'react';
import { onAuthStateChange } from './auth/auth'
import { useEffect } from 'react';
import React from 'react';
import { GoodPaneUser } from './types/types';

const UserContext = React.createContext<GoodPaneUser>(null);
const UserProvider = UserContext.Provider;

function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserProvider value={user}>
      <Switch>
        <Route path="/app/:uuid">
          <Editor></Editor>
        </Route>
        <Route path="/">
          <NewProtoApp></NewProtoApp>
        </Route>
      </Switch>
    </UserProvider> 
  )
}

export {App, UserContext};
