import ProtoComponentTagBox from "../components/ProtoComponentTagBox";

function Button(props) {
  const component = (
    <button className="max-h-full h-full w-full bg-white hover:bg-gray-100 text-gray-800  border border-gray-400 rounded">
      Click me
    </button>
  );
  return (
    <ProtoComponentTagBox
      name={props.component.name}
      component={component}
    ></ProtoComponentTagBox>
  );
}

export default Button;
