const NewMenu = (props) => {
  const handleButtonClick = () => {
    console.log("handling click from newmenu");
    props.clickHandler();
  };
  return (
    <div className="w-40 divide-y rounded-md p-2 absolute right-6 bottom-6 gp-bg text-white text-s">
      <div>
        <button
          onClick={handleButtonClick}
          className="hover:bg-pink-500 pl-2 rounded-t-sm w-full text-left"
        >
          New Resource
        </button>
      </div>
      <div>
        <button
          onClick={handleButtonClick}
          className="hover:bg-pink-500 pl-2 w-full text-left"
        >
          New App
        </button>
      </div>
      <div>
        <button
          onClick={handleButtonClick}
          className="hover:bg-pink-500 pl-2 rounded-b-sm w-full text-left"
        >
          Settings
        </button>
      </div>
    </div>
  );
};

export default NewMenu;
