import Draggable from "react-draggable";
import JSONTree from "react-json-tree";
import { GRID_SIZE_IN_PIXELS } from "../utils/contants";

type AppStateProps = any;
function AppState(props: AppStateProps) {
  return (
    <Draggable
      axis="both"
      grid={[GRID_SIZE_IN_PIXELS, GRID_SIZE_IN_PIXELS]}
      scale={1}
      bounds={"parent"}
    >
      <div className="absolute top-0 right-0 m-2 trform gp-bg rounded-lg w-64 content-center">
        <div
          id="code-area"
          className="max-h-64  p-2 text-white text-xs overflow-y-scroll font-mono l styled-sb styled-sb-tb"
        >
          <JSONTree
            hideRoot={true}
            data={props}
            sortObjectKeys={true}
            shouldExpandNode={() => {
              return true;
            }}
          ></JSONTree>
        </div>
      </div>
    </Draggable>
  );
}

export default AppState;
