import { Component } from "react";

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props };
    }

    render() {
        return <div className="fixed">Map</div>;
    }
}

export default Map;
